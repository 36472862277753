<template>
  <div>
    <a
      v-if="browser === 'Chrome' || !['Chrome', 'Edge'].includes(browser)" class="download-btn"
      target="_blank"
      :style="{backgroundColor: btnBg}"
      :href="googleDownloadUrl" aria-label="download arvin"
      @click="handleDownload">
      <nuxt-icon name="chrome" class="fs-14 lg:fs-28 pr-6px lg:pr-12px" :filled="true" />
      <span>{{ btnText ? btnText : 'Add to Chrome' }} {{ btnTextSuffix ? `- ${btnTextSuffix}` : '' }}</span>
    </a>
    <a
      v-if="browser === 'Edge' || !['Chrome', 'Edge'].includes(browser)" class="download-btn"
      target="_blank"
      :style="{backgroundColor: btnBg}"
      :href="edgeDownloadUrl" aria-label="download arvin"
      @click="handleDownload">
      <nuxt-icon name="edge" class="fs-14 lg:fs-28 pr-6px lg:pr-12px" :filled="true" />
      <span>{{ btnText ? btnText : 'Add to Edge' }} {{ btnTextSuffix ? `- ${btnTextSuffix}` : '' }}</span>
    </a>
  </div>
</template>
<script lang="ts" setup>

	defineProps({
		btnText: {
			type: String,
			default: ''
		},
		btnBg: {
			type: String,
			default: '#282A37'
		},
		btnTextSuffix: {
			type: String,
			default: `It's Free！`
		}
	});

	const runtimeConfig = useRuntimeConfig();
	const { googleDownloadUrl, edgeDownloadUrl } = runtimeConfig.public;
	const browser = getBrowserName();

	function handleDownload () {
		reportEvent('Web_Homepage_Install_Btn_Click');
	}
</script>

<style lang="less" scoped>
.download-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  text-decoration: none;
  @apply hover:opacity-80 lg:mx-0 w-280px h-40px rounded-40px text-14px lg:w-360px lg:h-60px lg:rounded-60px lg:text-20px font-500;
}
</style>
